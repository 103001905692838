import React from "react";
import styled from "styled-components";
import PreviewCompatibleImage from "./PreviewCompatibleImage";
import { Link } from "gatsby";
import { color30, color50, colorLink, colorMain } from "../styles";

const Wrapper = styled.div`
  display: block;
  margin: 32px 0;
  cursor: pointer;
  text-decoration: none;
  border-bottom: 1px solid ${color30};
  padding-bottom: 24px;

  &:last-of-type {
    border-bottom: none;
  }
`;
const Display = styled.div`
  overflow: hidden;
  border-radius: 8px;
  opacity: 0.85;
  margin-bottom: 15px;
`;
const Title = styled.h2`
  margin: 0;
  padding: 2px 18px 0 0;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 133%;
  display: flex;
  align-items: center;
  letter-spacing: -0.019em;
  color: ${colorLink};
`;
const SubTitle = styled.h3`
  margin: 0;
  padding: 0 18px 0 0;
  color: ${color50};
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 150%;
  letter-spacing: -0.011em;
`;
const Text = styled.p`
  margin: 0;
  padding: 12px 18px 0 0;
  display: block;
  color: ${colorMain};
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 150%;
  letter-spacing: -0.011em;
`;

const ThoughtSnippet = ({ post }) => {
  const { frontmatter, fields, excerpt } = post;
  return (
    <Wrapper className="ThoughtSnippet" as={Link} to={fields.slug}>
      {frontmatter.featuredImage && (
        <Display className="ThoughtSnippet-Display">
          {frontmatter.featuredImage.childImageSharp && (
            <PreviewCompatibleImage
              imageInfo={{
                image: frontmatter.featuredImage,
                alt: `featured image thumbnail for post ${frontmatter.title}`
              }}
            />
          )}
        </Display>
      )}
      <SubTitle>{frontmatter.subTitle}</SubTitle>
      <Title>{frontmatter.title}</Title>
      <Text>{excerpt}</Text>
    </Wrapper>
  );
};

export default ThoughtSnippet;

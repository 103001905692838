import React from "react";
import PropTypes from "prop-types";
import { Link, graphql, StaticQuery } from "gatsby";
import JourneySnippet from "./JourneySnippet";
import styled from "styled-components";
import SectionTitle from "./SectionTitle";
import Button from "./Button";
import IconCamera from "./Icons/IconCamera";

const ButtonWrapper = styled.div`
  padding: 16px 0 0;
`;

const Top = styled.div`
  padding: 24px 0 32px;
`;

class LatestPhoto extends React.Component {
  render() {
    const { data } = this.props;
    const { edges: posts } = data.allMarkdownRemark;

    if (posts && posts.length < 1) {
      return "";
    }

    return (
      <Top>
        <SectionTitle title="My latest photo"><IconCamera /></SectionTitle>

        {posts &&
        posts.map(({ node: post }) => (
          <JourneySnippet post={post} alt="Photos" key={post.id} />
        ))}

        <ButtonWrapper>
          <Button options={{ as: Link, to: "/photos" }}>
            <IconCamera />
            Browser My Photos
          </Button>
        </ButtonWrapper>
      </Top>
    );
  }
}

LatestPhoto.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array
    })
  })
};

export default () => (
  <StaticQuery
    query={graphql`
      query LatestFeedQuery {
        allMarkdownRemark(
          sort: { order: DESC, fields: [frontmatter___date] }
          limit: 1
          filter: {
            frontmatter: {
              draft: { eq: false }
              templateKey: { eq: "photo-post" }
            }
          }
        ) {
          edges {
            node {
              excerpt(pruneLength: 400)
              id
              fields {
                slug
              }
              frontmatter {
                title
                templateKey
                date(formatString: "MMMM DD, YYYY")
                featuredPost
                featuredImage {
                  childImageSharp {
                    fluid(maxWidth: 608, quality: 100) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={(data, count) => <LatestPhoto data={data} count={count} />}
  />
);

import React, { Component } from "react";
import PropTypes from "prop-types";
import Layout from "../components/Layout";
import IndexWrapper from "../components/ContentWrappers/IndexWrapper";
import { Context } from "../state";

class IndexPage extends Component {
  static contextType = Context;

  componentDidMount() {
    const [, dispatch] = this.context;
    dispatch({ type: "PAGE_TYPE", payload: "index" });
  }

  render() {
    return (
      <Layout>
        <IndexWrapper />
      </Layout>
    );
  }
}

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object
    })
  })
};

export default IndexPage;

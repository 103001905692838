import React from "react";
import styled from "styled-components";
import PreviewCompatibleImage from "./PreviewCompatibleImage";
import { colorMain, clearBg, maxMobile } from "../styles";
import { graphql, StaticQuery } from "gatsby";

const Wrapper = styled.div`
  padding-top: 16px;
  padding-bottom: 36px;
  background: ${clearBg};

  .container {
    display: flex;
    align-items: center;
  }

  @media (${maxMobile}) {
    .container {
      flex-direction: column;
    }
  }
`;

const Display = styled.div`
  flex: 0 1 159px;
  margin-right: 20px;

  @media (${maxMobile}) {
    flex: 0 1 auto;
    width: 159px;
    margin-top: 16px;
    order: 2;

    .container {
      flex-direction: column;
    }
  }
`;

const Content = styled.div`
  max-width: 700px;
  flex-shrink: 2;
`;

const Title = styled.h1`
  margin: 0 0 12px;
  padding: 0;
  font-style: normal;
  font-weight: normal;
  font-size: 30px;
  line-height: 133%;
  letter-spacing: -0.021em;
  color: ${colorMain};
`;

const Text = styled.p`
  margin: 0;
  padding: 0;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 133%;
  letter-spacing: -0.014em;
  color: ${colorMain};
`;

const PageIntro = ({ image }) => {
  return (
    <Wrapper>
      <div className="container">
        <Display>
          {image && <PreviewCompatibleImage imageInfo={image} />}
        </Display>
        <Content>
          <Title>Hi! I’m Darren Rowse</Title>
          <Text>
            Speaker, Author, Blogger, Podcaster & Founder of ProBlogger &
            Digital Photography School. Ambassador for WorldVision Aus. Father
            of 3 boys & husband to Vanessa.
          </Text>
        </Content>
      </div>
    </Wrapper>
  );
};

export default () => (
  <StaticQuery
    query={graphql`
      query darrenIntroImage {
        file(relativePath: { eq: "darren-rowse.png" }) {
          childImageSharp {
            fluid(maxWidth: 159, maxHeight: 159, quality: 100) {
              ...GatsbyImageSharpFluid
              ...GatsbyImageSharpFluidLimitPresentationSize
            }
          }
        }
      }
    `}
    render={(data, count) => <PageIntro image={data.file} />}
  />
);

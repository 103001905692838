import React from "react";
import styled from "styled-components";
import { colorLink } from "../styles"

const Wrapper = styled.div`
  display: flex;
  align-content: center;
  justify-content: center;
`;

const Title = styled.h3`
  margin: 0;
  padding: 2px 18px 0 0;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 133%;
  display: flex;
  align-items: center;
  letter-spacing: -0.019em;
  color: ${colorLink};
`;

const Icon = styled.span`
  color: ${colorLink};
  display: inline-flex;
  align-items: center;
  margin-right: 18px;
`;

const SectionTitle = ({title, children}) => {
  return (
    <Wrapper>
      <Icon>{children}</Icon>
      <Title>{title}</Title>
    </Wrapper>
  );
};

export default SectionTitle;

import React from "react";
import styled from "styled-components";
import PreviewCompatibleImage from "./PreviewCompatibleImage";
import { Link } from "gatsby";
import { colorLinkBg } from "../styles";

const Wrapper = styled.div`
  display: block;
  margin: 32px 0;
  cursor: pointer;
  text-decoration: none;
  position: relative;
  border-radius: 8px;
  overflow: hidden;

  &:before {
    content: "";
    z-index: 2;
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    left: 0;
    transform: translateY(20%);
    background: linear-gradient(
      180deg,
      rgba(81, 81, 81, 0) 0%,
      rgba(81, 81, 81, 0.0086472) 6.67%,
      rgba(81, 81, 81, 0.03551) 13.33%,
      rgba(81, 81, 81, 0.0816599) 20%,
      rgba(81, 81, 81, 0.147411) 26.67%,
      rgba(81, 81, 81, 0.231775) 33.33%,
      rgba(81, 81, 81, 0.331884) 40%,
      rgba(81, 81, 81, 0.442691) 46.67%,
      rgba(81, 81, 81, 0.557309) 53.33%,
      rgba(81, 81, 81, 0.668116) 60%,
      rgba(81, 81, 81, 0.768225) 66.67%,
      rgba(81, 81, 81, 0.852589) 73.33%,
      rgba(81, 81, 81, 0.91834) 80%,
      rgba(81, 81, 81, 0.96449) 86.67%,
      rgba(81, 81, 81, 0.991353) 93.33%,
      #515151 100%
    );
    mix-blend-mode: multiply;
    opacity: 0.85;
    transition: 0.2s opacity;
  }

  &:hover,
  &:active {
    &:before {
      opacity: 1;
    }
  }
`;
const Display = styled.div`
  overflow: hidden;
  border-radius: 8px;
  opacity: 0.85;
`;
const Title = styled.h2`
  margin: 0;
  padding: 0;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 133%;
  display: flex;
  align-items: center;
  letter-spacing: -0.019em;
  color: ${colorLinkBg};
`;
const SubTitle = styled.h3`
  margin: 0;
  padding: 0;
  color: ${colorLinkBg};
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 150%;
  letter-spacing: -0.011em;
`;

const Content = styled.div`
  position: absolute;
  bottom: 26px;
  left: 32px;
  right: 32px;
  z-index: 3;
`;

const JourneySnippet = ({ post, alt = 'Journey'}) => {
  const { frontmatter, fields } = post;
  return (
    <Wrapper className="JourneySnippet" as={Link} to={fields.slug}>
      {frontmatter.featuredImage && (
        <Display className="JourneySnippet-Display">
          {frontmatter.featuredImage.childImageSharp && (
            <PreviewCompatibleImage
              imageInfo={{
                image: frontmatter.featuredImage,
                alt: `featured image thumbnail for post ${frontmatter.title}`
              }}
            />
          )}
        </Display>
      )}

      <Content>
        <Title>{frontmatter.title}</Title>
        <SubTitle>My {alt}</SubTitle>
      </Content>
    </Wrapper>
  );
};

export default JourneySnippet;

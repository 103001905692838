import React from "react";
import styled from "styled-components";
import { colorButton, colorMain } from "../styles";

const Wrapper = styled.span`
  display: flex;
  max-width: 100%;
  width: 237px;
  margin: 0 auto;
  background: ${colorButton};
  color: ${colorMain};
  padding: 20px 0 19px;
  box-shadow: 0px 0.298986px 0.318919px rgba(0, 0, 0, 0.00958669),
    0px 0.67932px 0.724608px rgba(0, 0, 0, 0.0139364),
    0px 1.18233px 1.26115px rgba(0, 0, 0, 0.0171642),
    0px 1.87823px 2.00345px rgba(0, 0, 0, 0.02),
    0px 2.89797px 3.09117px rgba(0, 0, 0, 0.0228358),
    0px 4.52206px 4.82353px rgba(0, 0, 0, 0.0260636),
    0px 7.50732px 8.00781px rgba(0, 0, 0, 0.0304133),
    0px 15px 16px rgba(0, 0, 0, 0.04);
  border-radius: 8px;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  transition: 0.2s opacity;
  cursor: pointer;

  &:hover,
  &:active {
    opacity: 0.8;
  }
`;

const Text = styled.span`
  display: inline-flex;
  align-items: center;
  justify-content: center;

  svg {
    margin-right: 4px;
  }
`;

const Button = ({ options, children }) => {
  return (
    <Wrapper {...options}>
      <Text>{children}</Text>
    </Wrapper>
  );
};

export default Button;

import ThoughtsFeed from "../ThoughtsFeed";
import PropTypes from "prop-types";
import React from "react";
import PageIntro from "../PageIntro";
import JourneyFeed from "../JourneyFeed";
import styled from "styled-components";
import Button from "../Button";
import IconFlower from "../Icons/IconFlower";
import IconSign from "../Icons/IconSign";
import { graphql, Link, useStaticQuery } from "gatsby";
import { color30, minDesktop, mainBg } from "../../styles";
import SectionTitle from "../SectionTitle";
import LatestPhoto from "../LatestPhoto";

const Grid = styled.div`
  @media (${minDesktop}) {
    display: grid;
    grid-template-columns: 55.56% 1fr;
    grid-gap: 31px;
    padding-top: 16px;
    padding-bottom: 24px;
  }

  padding-bottom: 80px;
`;

const ButtonWrapper = styled.div`
  padding: 32px 0 48px;
`;

const JourneyButton = styled(ButtonWrapper)`
  padding: 16px 0 0;
`;

const PostWrapper = styled.div`
  padding-bottom: 64px;

  @media (${minDesktop}) {
    border-right: 1px solid ${color30};
    margin: 0;

    .ThoughtSnippet-Display {
      padding-right: 18px;
    }
  }
`;

const Background = styled.div`
  background: ${mainBg};
`;

const Top = styled.div`
  margin-bottom: 26px;
`;

const query = graphql`
  query hasPhotosIndex {
    photos:allMarkdownRemark(
      limit: 1
      filter: {
        frontmatter: {
          draft: { eq: false }
          templateKey: { eq: "photo-post" }
        }
      }
    ) {
      edges {
        node {
          id
        }
      }
    }
  }
`;

export const IndexWrapper = () => {
  const { photos } = useStaticQuery(query);

  return (
    <>
      <PageIntro />

      <Background>
        <div className="container">
          <Grid>
            <PostWrapper>
              <ThoughtsFeed />
              <ButtonWrapper>
                <Button options={{ as: Link, to: "/thoughts" }}>
                  <IconFlower />
                  Browser My Thoughts
                </Button>
              </ButtonWrapper>
            </PostWrapper>
            <Top>
              <LatestPhoto />
              <div>
                {photos && photos.hasOwnProperty("edges") && photos.edges.length > 0 &&
                <SectionTitle title="My journey"><IconSign /></SectionTitle>}
                <JourneyFeed />
                <JourneyButton>
                  <Button options={{ as: Link, to: "/journey" }}>
                    <IconSign />
                    Browser My Journey
                  </Button>
                </JourneyButton>
              </div>
            </Top>
          </Grid>
        </div>
      </Background>
    </>
  );
};

IndexWrapper.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  description: PropTypes.string
};

export default IndexWrapper;
